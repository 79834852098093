<template>
  <div>
    <BarFloating :distance="0">
      <div class="fixed-bar-options" :showButtonClose="true" v-show="totalSelected > 0">
        <div>
          <div class="side-by-side">
            <div class="div-totalSelected side-by-side">
              <span class="title">
                {{ totalSelected }}
              </span>
            </div>

            <Button _key="btnRemoveItem" type="danger" size="small" classIcon="fa-solid fa-trash" tooltip="Remover"
              eventName="removeItemFixedBar" />

            <Button v-if="!isOperational" _key="btnOptions1" type="danger" tooltip="Aplicar Desconto" size="small"
              classIcon="fa-solid fa-percent" eventName="applyDiscountItemRent" />

            <Button v-if="!isOperational" _key="btnOptions2" backGroundColor="#156EBE" color="white"
              tooltip="Recalcular Valor" size="small" classIcon="fa-solid fa-retweet" eventName="recalculateValue" />

            <Button v-if="rent.statusRental == 2 && !isOperational" _key="btnOptions3" backGroundColor="#02D1C1"
              color="white" tooltip="Status de Faturamento" size="small" classIcon="fa-solid fa-file-invoice"
              eventName="updateStatusInvoicing" />

            <Button v-if="rent.statusRental == 2" _key="btnOptions4" backGroundColor="#DF800E" color="white"
              tooltip="Desativar Movimentação" size="small" classIcon="fa-solid fa-shuffle"
              eventName="disableMovement" />
          </div>

          <div class="div-remove side-by-side" @click="closedFixedBar()">
            <i class="fa-solid fa-circle-xmark"></i>
          </div>
        </div>
      </div>
    </BarFloating>

    <Modal :title="modalOptions.title" :width="modalOptions.width" v-if="showModal('modalFixedBar')">
      <div v-if="modalOptions.name == 'applyDiscountItemRent'">
        <Alert type="info">
          <span>
            Não será aplicado desconto em produto faturado!
          </span>
        </Alert>
        <ApplyDiscount :rentId="rent.id" :selected="selected" />
      </div>
      <DisableMovement :selected="selected" v-if="modalOptions.name == 'disableMovement'" />
      <UpdateStatusInvoicing :selected="selected" v-if="modalOptions.name == 'updateStatusInvoicing'" />
      <RecalculateValue :selected="selected" v-if="modalOptions.name == 'recalculateValue'" />
    </Modal>
  </div>
</template>
<script>


import FixedBar from "@nixweb/nixloc-ui/src/component/layout/FixedBar.vue";
import BarFloating from "@nixweb/nixloc-ui/src/component/layout/BarFloating.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import ApplyDiscount from './ApplyDiscount.vue'
import DisableMovement from './DisableMovement.vue'
import UpdateStatusInvoicing from './UpdateStatusInvoicing.vue'
import RecalculateValue from './RecalculateValue.vue'
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ItemRentFixedBar",
  components: { FixedBar, Button, Alert, Dropdown, BarFloating, Modal, ApplyDiscount, UpdateStatusInvoicing, RecalculateValue, DisableMovement },
  props: {
    selected: Array,
    rent: Object,
    isOperational: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      showFloatingDiv: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.checkPosition);
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkPosition);
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    totalSelected() {
      return this.selected.length;
    },
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "hideModal", "removeLoading"]),
    checkPosition() {
      const currentPosition = window.scrollY;
      const distance = 250;

      if (currentPosition > distance) {
        this.showFloatingDiv = true;
      } else {
        this.showFloatingDiv = false;
      }
    },
    closedFixedBar() {
      this.addEvent({ name: "closedFixedBar" });
    }
  },
  watch: {
    event: {
      handler(event) {

        this.removeLoading(["btnOptions1", "btnOptions2", "btnOptions3", "btnOptions4"]);

        if (event.name == "applyDiscountItemRent") {
          this.modalOptions = {
            name: "applyDiscountItemRent",
            title: "Aplicar Desconto",
            width: 500,
          };
          this.openModal("modalFixedBar");
        }

        if (event.name == "recalculateValue") {
          this.modalOptions = {
            name: "recalculateValue",
            title: "Recalcular Valor",
            width: 500,
          };
          this.openModal("modalFixedBar");
        }

        if (event.name == "disableMovement") {
          this.modalOptions = {
            name: "disableMovement",
            title: "Movimentação de Estoque",
            width: 500,
          };
          this.openModal("modalFixedBar");
        }

        if (event.name == "updateStatusInvoicing") {
          this.modalOptions = {
            name: "updateStatusInvoicing",
            title: "Status de Faturamento",
            width: 500,
          };
          this.openModal("modalFixedBar");
        }

      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.fixed-bar-options {
  max-width: 1150px;
}

.div-remove {
  margin-top: -6px;
  float: right;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: darkgray;
}

.div-totalSelected {
  width: 20px;
  margin-left: 10px;
  font-size: 15px;
}



.total-selected {
  font-size: 16px;
}
</style>