<template>
  <div>
    <CheckboxSimple title="TODOS PERÍODOS" v-show="showPeriodAll && data.length > 1" :markFormDirty="false"
      v-model="showAll" />
    <div :class="{ 'show-all': showAll }" class="div-main">
      <div :class="{ 'display-period-rent-selected': period.id == selectedPeriod.id || showAll }"
        class="display-period-rent" v-for="period in data" :key="period.id">
        <div @click="select(period)">
          <b-popover :target="period.id" triggers="hover" placement="top">
            <template #title>Saída e Retorno</template>
            <div class="text-center">
              <DisplayPeriodRent :fontSize="13" :periodRent="period.fullPeriod" :showOnlyDeliveryDevolution="true"
                :showDeliveryDevolution="true" />
            </div>
          </b-popover>
          <DisplayPeriodRent :id="period.id" :fontSize="13" :periodRent="period.fullPeriod">
            <span slot="start" class="check" v-if="period.id == selectedPeriod.id || showAll">
              <i class="fa-solid fa-circle-check"></i>
            </span>
          </DisplayPeriodRent>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import DisplayPeriodRent from "@nixweb/nixloc-ui/src/component/rental/DisplayPeriodRent";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "SelectOptionPeriodRent",
  props: ["value"],
  components: {
    DisplayPeriodRent,
    CheckboxSimple,
  },
  props: {
    rentId: String,
    showPeriodAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      periodRentId: this.$route.params.periodRentId,
      urlGetApi: "/api/v1/rental/period-rent/get-all",
      data: [],
      showAll: false,
      selectedPeriod: {},
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["addEvent"]),
    getAll() {
      let params = {
        url: this.urlGetApi,
        obj: {
          rentId: this.rentId,
          periodRentId: this.periodRentId,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.data = response.content.data;
        let firstPeriod = response.content.data[0];
        this.selectedPeriod = firstPeriod;
        this.$emit("input", {
          showAll: this.showAll,
          period: this.selectedPeriod,
        });
        this.addEvent({
          name: "selectedPeriodRent",
          data: { showAll: this.showAll, period: this.selectedPeriod },
        });
        this.addEvent({
          name: "totalRecordsPeriod",
          data: { totalRecords: this.data.length },
        });
      });
    },
    select(period) {
      if (!this.showAll) {
        this.selectedPeriod = period;
        this.$emit("input", {
          showAll: this.showAll,
          period: this.selectedPeriod,
        });
        this.addEvent({
          name: "selectedPeriodRent",
          data: { showAll: this.showAll, period: this.selectedPeriod },
        });
      }
    },
  },
  watch: {
    showAll: {
      handler() {
        this.$emit("input", {
          showAll: this.showAll,
          period: this.selectedPeriod,
        });
        this.addEvent({
          name: "selectedPeriodRent",
          data: { showAll: this.showAll, period: this.selectedPeriod },
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-main {
  margin-top: 10px;
  margin-bottom: 10px;
}

.display-period-rent {
  display: inline-block;
  cursor: pointer;
  max-width: 550px;
  border: 1px solid #e5e4e8;
  background-color: white;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 7px;
  font-size: 14px;
  font-weight: normal;
  margin: 5px;
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 2%);
}

.check {
  margin-right: 5px;
  color: #4680a5;
}

.display-period-rent-selected {
  border: 1px #4680a5 solid;
}

.show-all {
  opacity: 0.4;
}
</style>
