<template>
  <div>
    <Timeline :timelineItems="timelineItems" :loading="loading" />
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Timeline from "@nixweb/nixloc-ui/src/component/shared/TimeLine.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "HistoryMoviment",
  components: {
    Loading,
    Timeline,
    ScrollBar,
  },
  data() {
    return {
      urlGet: "/api/v1/stock/history-moviment/get-all",
      item: {},
      content: {},
      loading: false,
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 10,
      },
      timelineItems: [],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      let params = {
        url: this.urlGet,
        obj: {
          itemsRentId: this.item.id,

          ...this.baseParams,
        },
      };
      this.getApi(params).then((response) => {
        this.timelineItems = [];
        let self = this;
        response.content.data.map(function (item, key) {
          let date = new Date(item.year, item.month - 1, item.day);
          let obj = {
            from: date,
            title: `Quantidade ${item.quantity}`,
            description: item.description,
            color: item.color,
            showDayAndMonth: true,
          };
          self.timelineItems.push(obj);
        });
        setTimeout(function () {
          self.loading = false;
        }, 300);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "totalizationAndHistory") {
          this.loading = true;
          this.item = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.timeline {
  max-width: 1000px !important;
}

.timeline-item .date-item {
  font-size: 14px !important;
  letter-spacing: 1px !important;
  font-weight: normal !important;
}
</style>