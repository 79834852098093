<template>
  <div>
    <Select
      title="Endereço"
      field="address"
      :required="true"
      url="/api/v1/shared/address/select-all"
      :showNewRegister="false"
      :propsParams="{ any: rent.customer.id }"
      v-model="address"
    >
    </Select>
    <div class="text-center div-btn-address">
      <Button
        _key="btnImportAddressRent"
        title="Importar"
        :classIcon="'fa-solid fa-arrow-right-from-arc'"
        type="info"
        size="medium"
        :clicked="importAddress"
      />
    </div>
  </div>
</template>

<script>
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations } from "vuex";

export default {
  name: "ImportAddress",
  props: {
    rent: Object,
  },
  components: {
    Select,
    Button,
  },
  data() {
    return {
      address: {},
    };
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
    importAddress() {
      this.addEvent({ name: "importAddress", data: this.address });
    },
  },
};
</script>
<style scoped>
.div-btn-address {
  margin-top: 20px;
}
</style>