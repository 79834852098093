<template>
  <div>
    <div v-if="data.type == 5">
      <div v-if="rent.statusRental != 2">
        <div class="badge badge-material">
          Material
        </div>
      </div>
      <div v-else>
        <div v-if="data.statusStockItemsRent == 5 || data.statusStockItemsRent == 7" class="badge badge-material">
          Mat...Realizado
        </div>
        <div v-if="data.totalMaterialRented == 0 && data.statusStockItemsRent != 7" class="badge badge-material">
          Material Pendente
        </div>
        <div v-if="data.totalMaterialRented != data.totalMaterialReturned && data.statusStockItemsRent != 7"
          class="badge badge-material">
          Mat...Pendente
        </div>
      </div>
    </div>
    <div v-if="data.type == 3">
      <div v-if="rent.statusRental != 2">
        <div class="badge badge-pending">
          Serviço
        </div>
      </div>
      <div v-else>
        <div v-if="!data.serviceDone" class="badge badge-pending">
          Serv..Pendente
        </div>
        <div v-else class="badge badge-service">
          Serv..Realizado
        </div>
      </div>
    </div>
    <div v-if="data.type != 3 && data.type != 5">
      <div class="badge badge-available" v-if="data.statusStockItemsRent == 1">
        Não Reservado
      </div>
      <div class="badge badge-reserved" v-if="data.statusStockItemsRent == 2">
        Reservado
      </div>
      <div class="badge badge-rent" v-if="data.statusStockItemsRent == 3">
        Locado
      </div>
      <div class="badge badge-rent" v-if="data.statusStockItemsRent == 4">
        Locado Parcial
      </div>
      <div class="badge badge-returned" v-if="data.statusStockItemsRent == 5">
        Retornado
      </div>
      <div class="badge badge-partial-returned" v-if="data.statusStockItemsRent == 6">
        Retornado Parcial
      </div>
      <div class="badge badge-renewed" v-if="data.statusStockItemsRent == 7">
        Renovado
      </div>
      <div class="badge badge-renewed" v-if="data.statusStockItemsRent == 7">
        Renovado Parcial
      </div>
    </div>


  </div>
</template>
<script>
export default {
  name: "ItemsRentStatusStock",
  props: {
    data: Object,
    rent: Object

  },
};
</script>
<style scoped>
.badge {
  font-size: 12px !important;
  margin-left: 5px;
  border-radius: 10px !important;
  padding: 8px;
  color: white;
}

.badge-material {
  background-color: #812805;
}


.badge-pending {
  background-color: #F1BC31;
}

.badge-service {
  background-color: #df800d;
}

.badge-available {
  background-color: #8fbc8f;
}

.badge-reserved {
  background-color: #008b8b;
}

.badge-rent {
  background-color: #00008b;
}

.badge-returned {
  background-color: #b8860b;
}

.badge-partial-returned {
  background-color: #b8860b;
}

.badge-renewed {
  background-color: #1c1cc5;
}
</style>
