<template>
    <div class="div-discount">
        <Alert type="info">
            <span>Por favor, note que os valores dos produtos selecionados serão ajustados conforme a tabela.</span>
        </Alert>
        <Alert type="warning">
            <span>Produto faturado, não será recalculado!</span>
        </Alert>
        <div class="div-apply text-center">
            <Button _key="btnRecalculateValue" title="Aplicar" backGroundColor="#156EBE" color="white" size="medium"
                :clicked="update" />
        </div>
    </div>
</template>
<script>

import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "RecalculateValue",
    props: ["selected"],
    components: { Alert, Button },
    data() {
        return {
            urlUpdate: "/api/v1/rental/items-rent/recalculate-value",
        }
    },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        update() {
            let params = {
                url: this.urlUpdate,
                obj: {
                    listIds: this.selected,
                }
            };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "updateFixedBar" });
                    this.hideModal();
                }
                this.removeLoading(["btnRecalculateValue"]);
            });
        }
    }
}

</script>