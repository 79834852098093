<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40"
          v-model="groupRent.name" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveGroupRent" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveGroupRent" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import GroupRent from "@/components/modules/rental/group-rent/GroupRent.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "GroupRentCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, Button },
  data() {
    return {
      formName: "groupRentCreateUpdate",
      groupRent: new GroupRent(),
      urlCreate: "/api/v1/rental/group-rent/create",
      urlUpdate: "/api/v1/rental/group-rent/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveGroupRent() {
      if (this.groupRent.id) {
        let params = { url: this.urlUpdate, obj: this.groupRent };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSaveGroupRent"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.groupRent };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["btnSaveGroupRent"]);
        });
      }
    },
    update(data) {
      this.groupRent = new GroupRent();
      this.groupRent.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "groupRentCreate") {
          this.resetValidation(this.formName);
          this.groupRent = new GroupRent();
        }
        if (event.name == "groupRentUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
