<template>
  <div>
    <Loading :center="false" v-if="loading" />
    <div v-else>
      <ScrollBar :minHeight="200" :maxHeight="300">
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th>
                <span class="title-header">Patrimônio</span>
              </th>
              <th class="text-center">
                <span class="title-header">Status</span>
              </th>
            </tr>
          </thead>
          <tbody v-for="item in content.data">
            <tr>
              <td>
                <span> {{ item.barCode }}</span>
              </td>
              <td class="text-center">
                <div class="badge badge-returned" v-if="item.status == 2">
                  Retornado
                </div>
                <div class="badge badge-rent" v-else>Locado</div>
              </td>
            </tr>
          </tbody>
          <tbody v-show="content.data == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum patrimônio encontrado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ScrollBar>
      <br />
    </div>
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PatrimonyMoviment",
  components: {
    Loading,
    ScrollBar,
  },
  data() {
    return {
      urlGet: "/api/v1/stock/patrimony-moviment/get-all-by-item",
      item: {},
      content: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      let params = {
        url: this.urlGet,
        obj: {
          itemsRentId: this.item.id,
        },
      };
      this.getApi(params).then((response) => {
        this.content = response.content;

        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 300);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "totalizationAndHistory") {
          this.loading = true;
          this.item = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.badge {
  font-size: 12px !important;
  margin-left: 5px;
  border-radius: 10px !important;
  padding: 8px;
  color: white;
}

.badge-rent {
  background-color: #00008b;
}

.badge-returned {
  background-color: #b8860b;
}
</style>