<template>
  <div>
    <Loading :center="false" v-if="loading" />
    <div v-else class="text-center">
      <b-row>
        <b-col sm="3"
          ><DisplayTotalization
            title="Reservado"
            :value="totalization.reserved"
            :currency="false"
            _style="color: #018B8B"
          />
        </b-col>
        <b-col sm="3"
          ><DisplayTotalization
            title="Saída"
            :value="totalization.rented"
            :currency="false"
            _style="color: #00058B"
          />
        </b-col>
        <b-col sm="3"
          ><DisplayTotalization
            title="Retornado"
            :value="totalization.returned"
            :currency="false"
            _style="color: #b8860b"
          />
        </b-col>
        <b-col sm="3"
          ><DisplayTotalization
            title="Locado"
            :value="totalization.final"
            :currency="false"
            _style="color: #00058B"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Totalization",
  components: {
    DisplayTotalization,
    Loading,
  },
  data() {
    return {
      urlGet: "/api/v1/stock/items-moviment/totalization-by-item",
      item: {},
      totalization: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getTotalization() {
      let params = {
        url: this.urlGet,
        obj: {
          itemsRentId: this.item.id,
        },
      };
      this.getApi(params).then((response) => {
        this.totalization = response.content;

        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 300);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "totalizationAndHistory") {
          this.loading = true;
          this.item = event.data;
          this.getTotalization();
        }
      },
      deep: true,
    },
  },
};
</script>