<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <b-row>
        <b-col sm="12">
          <Select title="Nome" field="type" :formName="formName" :required="true"
            url="/api/v1/human-resources/collaborator/select-all" :propsParams="{ isFreelance: true }"
            v-model="team.collaborator" nameNewRegister="consultantCreateUpdate" :showNewRegister="false"
            titleNewRegister="Consultor" :widthNewRegister="800" :heightNewRegister="250">
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <DateTime title="Data Inicio" field="dateStart" format="DD/MM/YYYY" type="date" :required="true" placeholder
            :formName="formName" v-model="team.dateStart" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Hora" field="timeStart" format="HH:mm" :required="true" type="time" placeholder
            :formName="formName" v-model="team.timeStart" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Data Fim" field="dateEnd" format="DD/MM/YYYY" type="date" :required="true" placeholder
            :formName="formName" v-model="team.dateEnd" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Hora" field="timeEnd" format="HH:mm" type="time" :required="true" placeholder
            :formName="formName" v-model="team.timeEnd" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <EditorHtml title="Descrição" :required="false" :maxLength="1000" v-model="team.description" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="3">
          <DateTime title="Data Pagamento" field="dueDate" format="DD/MM/YYYY" type="date" :required="false" placeholder
            :formName="formName" v-model="team.dueDate" />
        </b-col>
        <b-col sm="3">
          <InputDecimal title="Valor/Cachê" field="unitValue" :formName="formName" :required="false"
            :markFormDirty="false" :maxLength="5" type="float" v-model="team.value" />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col sm="12">
          <div class="text-right">
            <Button _key="saveTeam" type="success" title="Salvar" :disabled="!isFormValid(formName)"
              classIcon="fas fa-save" size="medium" :clicked="save" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import Team from "./Team.js"

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "TeamCreateUpdate",
  components: {
    InputText, DateTime, Button, Select, EditorHtml, InputDecimal, Loading
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      formName: "teamCreateUpdate",
      team: new Team(),
      urlCreate: "/api/v1/human-resources/team/create",
      urlUpdate: "/api/v1/human-resources/team/update",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    save() {
      this.team.rentId = this.rent.id;
      this.team.statusRental = this.rent.statusRental;

      if (this.team.id) {
        let params = { url: this.urlUpdate, obj: this.team };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["saveTeam"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.team };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["saveTeam"]);
        });
      }
    },
    update(data) {
      this.team = new Team();
      this.team.update(data);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "teamCreateUpdate") {
          this.loading = true;
          this.team = new Team();
          this.resetValidation(this.formName);
          this.loading = false;
        }
        if (event.name == "teamUpdate") {
          this.update(event.data);
          this.loading = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>