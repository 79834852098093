<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-main">
        <RentOperationalCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>


import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import RentOperationalCreateUpdate from '../../../components/modules/operational/rent/RentOperationalCreateUpdate.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "RentOperationalCreateUpdateView",
  components: {
    ViewTemplateWithSalveCancel,
    RentOperationalCreateUpdate
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      panel: {
        module: "Operacional",
        title: "Locação",
        formName: "rentOperationalCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),

  },
};
</script>
