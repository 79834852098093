<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="optionsConditional" />
    <PrintPreview v-if="printRent" module="Operational" :rentId="rent.id" :periodRentId="periodRent.id"
      :onHideModal="closeModal">
    </PrintPreview>
  </div>
</template>
<script>


import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import PrintPreview from '../../adm/document/PrintPreview.vue'

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "DropdownOptionsRent",
  props: {
    rent: Object,
    periodRentId: String,
    statusMovimentStock: Object,

  },
  components: {
    Dropdown,
    Modal,
    Select,
    PrintPreview
  },
  data() {
    return {
      documentId: "",
      periodRent: {},
      totalRecordsPeriod: 0,
      printRent: false,
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      options: [
        {
          title: "Compartilhar",
          showByStatus: [1, 2, 3],
          classIcon: "fa-sharp fa-regular fa-share-nodes",
          eventName: "printRent",
          hr: true,
        },
        {
          title: "Gerar Saída",
          showByStatus: [0],
          classIcon: "fa-solid fa-circle-arrow-right",
          eventName: "generateOutput",
          style: "color:#009183;",
        },
        {
          title: "Gerar Retorno",
          showByStatus: [0],
          classIcon: "fa-solid fa-circle-arrow-left",
          eventName: "generateReturn",
          style: "color:#b8860b;",
        },
        {
          title: "Gerar Renovação",
          showByStatus: [0],
          classIcon: "fa-solid fa-retweet",
          eventName: "generateRenewed",
          style: "color:#577696;",
        },

      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    optionsConditional() {
      let options = [];
      let statusRental = this.rent.statusRental;

      let reserved = this.statusMovimentStock.reserved;
      let reservedAndRented = reserved - this.statusMovimentStock.rented;
      let final = this.statusMovimentStock.final;


      this.options.forEach((item) => {
        if (
          item.eventName == "generateOutput" &&
          statusRental == 2
        ) {
          options.push(item);
        } else if (
          item.eventName == "generateReturn" &&
          final > 0 &&
          statusRental == 2
        ) {
          options.push(item);
        } else if (
          item.eventName == "generateRenewed" &&
          final > 0 &&
          statusRental == 2
        ) {
          options.push(item);
        } else {
          if (item.showByStatus.includes(statusRental)) options.push(item);
        }
      });

      return options;
    },
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    closeModal() {
      this.modalOptions = { name: "", title: "", width: 0 };
      this.printRent = false;
      this.periodRent = {};
      this.hideModal();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "totalRecordsPeriod")
          this.totalRecordsPeriod = event.data.totalRecords;

        if (event.name == "printRent") {
          this.printRent = true;
        }

        if (event.name == "emailSent") {
          this.printRent = false;
          this.periodRent = {};
        }

        if (event.name == "generateOutput") {
          this.$router.push({
            name: "generateOutput",
            params: { id: this.rent.id, periodRentId: this.periodRentId },
          });
        }

        if (event.name == "generateReturn") {
          this.$router.push({
            name: "generateReturn",
            params: { id: this.rent.id, periodRentId: this.periodRentId },
          });
        }

        if (event.name == "generateRenewed") {
          this.$router.push({
            name: "generateRenewed",
            params: { id: this.rent.id, periodRentId: this.periodRentId },
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.select-document {
  min-height: 100px;
}
</style>