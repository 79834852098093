<template>
    <div>
        <RadioGroup :stacked="true" :options="[
            { text: 'Faturado', value: 2 },
            { text: 'Não Faturado', value: 1 },
            { text: 'Não Fatura (Cancelado)', value: 3 },
        ]" v-model="status" />
        <div class="text-center">
            <Button _key="btnUpdate" title="Aplicar" backGroundColor="#02D1C1" color="white" size="medium"
                :clicked="update" />
        </div>
    </div>
</template>

<script>

import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
    name: "UpdateStatusInvoicing",
    props: ["selected"],
    components: {
        RadioGroup, Button
    },
    data() {
        return {
            urlUpdate: "/api/v1/rental/items-rent/update-status-invoice",
            status: 1
        }
    },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        update() {
            let params = { url: this.urlUpdate, obj: { listIds: this.selected, status: this.status } };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "updateFixedBar" });
                    this.hideModal();
                }
                this.removeLoading(["btnUpdate"]);
            });
        }
    }
}

</script>