<template>
    <div>
        <Alert type="info">
            <span>Ao selecionar a opção Não Reservado, o produto ficará indisponível para movimentação de
                estoque!</span>
        </Alert>
        <RadioGroup :stacked="false" :options="[
            { text: 'Reservado', value: 2 },
            { text: 'Não Reservado', value: 1 },
        ]" v-model="status" />
        <div class="text-center">
            <Button _key="btnUpdate" title="Aplicar" backGroundColor="#DF800E" color="white" size="medium"
                :clicked="update" />
        </div>
    </div>
</template>

<script>

import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "DisableMovement",
    props: ["selected"],
    components: {
        RadioGroup, Button, Alert
    },
    data() {
        return {
            urlUpdate: "/api/v1/rental/items-rent/disable-movement",
            status: 1
        }
    },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        update() {
            let params = { url: this.urlUpdate, obj: { listIds: this.selected, status: this.status } };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "updateFixedBar" });
                    this.hideModal();
                }
                this.removeLoading(["btnUpdate"]);
            });
        }
    }
}

</script>