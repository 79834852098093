<template>
  <div>
    <div v-show="!showTableDraggable">
      <ItemsRentOperationalCreate :rent="rent" />
      <br />
      <Loading type="line" :center="false" v-show="loading" />
      <TableTotalRecords :totalRecords="content.totalRecords" />
    </div>
    <ItemRentFixedBar :selected="selected" :rent="rent" :isOperational="true" />
    <ScrollBar id="items-rent" :minHeight="400" :maxHeight="800" :endScrollBar="endScrollBar">
      <table class="table table-responsive-xs" v-show="!showTableDraggable">
        <thead>
          <tr>
            <th class="td-checkbox">
              <i class="fa-regular fa-maximize icon-order-group icon-cursor"
                @click="changeGroupToTableDraggable(grouped)">
              </i>
            </th>
            <th>
              <span class="title-header">Produto </span>
            </th>
            <th class="text-center">
              <span class="title-header">Quantidade</span>
            </th>
            <th class="text-center">
              <span class="title-header">Estoque</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(data, groupName) in grouped">
          <td class="group" colspan="8">
            <div>
              <i v-if="data.length > 1" class="fa-regular fa-maximize icon-order icon-cursor"
                @click="changeItemToTableDraggable(data)">
              </i>
              <i v-else class="fa-solid fa-layer-group icon-order"></i>
              <span v-if="groupName" class="title-group">
                {{ groupName }}
              </span>
              <span v-else class="title-group">
                <span class="without-group">Sem grupo</span>
              </span>
            </div>
          </td>
          <tr v-for="item in data">
            <td class="td-checkbox td">
              <b-form-checkbox v-model="selected" :value="item.id" />
            </td>
            <td class="td-product td" @click="update(item)">
              <span>
                {{ item.productFullName }}
              </span>
              <br />
              <span class="description td" v-html="item.description"> </span>
            </td>
            <td class="text-center td-quantity td">
              <div>
                <span> {{ item.quantity }}</span>
                <span v-if="item.type != 3">
                  ({{ item.unitOfMeasurementName }})
                </span>
              </div>
              <div class="quantity" @click="totalizationAndHistory(item)">
                <ItemsRentStatusStock :data="item" :rent="rent" />
              </div>
            </td>
            <td class="text-center td">
              <ItemRentStockAvailability v-if="item.type != 3" :data="item.available" :item="item"
                :showButtonBack="false" />
              <i class="icon-only-stock fa-solid fa-grid-2-plus" v-if="item.onlyStock"></i>
            </td>
            <td class="text-center td">
              <div v-if="item.type == 1"><i class="fas fa-box"></i></div>
              <div v-if="item.type == 2"><i class="fas fa-toolbox"></i></div>
              <div v-if="item.type == 3">
                <i class="fas fa-people-carry"></i>
              </div>
              <div v-if="item.type == 5">
                <i class="fa-solid fa-recycle"></i>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="12">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
      <TableDraggable urlOrderAllApi="/api/v1/rental/items-rent/order-all" v-if="showTableDraggable"
        :data="dataDraggable" :propsParam="{
          any: orderByDraggable,
          rentId: rent.id,
          periodRentid: periodRentId,
        }" />
    </ScrollBar>
    <Modal :title="modalOptions.title" :width="modalOptions.width" :onHideModal="onHideModal"
      v-show="showModal('optionsItemsRent')">
      <div v-show="modalOptions.name == 'confirmRemove'">
        <Alert type="warning">
          <span>
            Todas as movimentações relacionadas ao produto serão removidas. Esta
            ação é irreversível!
          </span>
        </Alert>
        <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="removeSelected" />
      </div>
      <div v-show="modalOptions.name == 'totalizationAndHistory'">
        <b-tabs>
          <b-tab title="Resumo" v-if="item.type == 1 || item.type == 2">
            <TotalizationItemsMoviment />
          </b-tab>
          <b-tab title="Patrimônio" v-if="!item.allowWithoutPatrimony">
            <PatrimonyMoviment />
          </b-tab>
          <b-tab title="Histórico">
            <HistoryMoviment />
          </b-tab>
        </b-tabs>
      </div>
      <ItemRentOperationalUpdate v-show="modalOptions.name == 'itemRentUpdate'" />
      <TimeLineAvailability v-show="modalOptions.name == 'timeLineAvailability'" />
    </Modal>
  </div>
</template>
<script>

import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import TableDraggable from "@nixweb/nixloc-ui/src/component/shared/TableDraggable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import ItemsRentOperationalCreate from './ItemsRentOperationalCreate.vue'
import ItemRentStockAvailability from '../../rental/items-rent/ItemRentStockAvailability.vue'
import ItemsRentStatusStock from '../../rental/items-rent/ItemsRentStatusStock.vue'
import HistoryMoviment from '../../stock/history-moviment/HistoryMoviment.vue'
import PatrimonyMoviment from '../../stock/items-moviment/PatrimonyMoviment.vue'
import TotalizationItemsMoviment from '../../stock/moviment/TotalizationItemsMoviment.vue'
import TimeLineAvailability from '../../stock/shared/TimeLineAvailability.vue'
import ItemRentFixedBar from '../../rental/items-rent/ItemRentFixedBar.vue'
import ItemRentOperationalUpdate from './ItemRentOperationalUpdate.vue'


import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsRentOperationalList",
  components: {
    Loading,
    ScrollBar,
    TableTotalRecords,
    TableDraggable,
    Toggle,
    Confirmation,
    ItemRentFixedBar,
    Modal,
    Alert,
    Button,
    ItemRentFixedBar,
    ItemsRentStatusStock,
    ItemRentStockAvailability,
    ItemsRentOperationalCreate,
    TotalizationItemsMoviment,
    PatrimonyMoviment,
    HistoryMoviment,
    TimeLineAvailability, ItemRentOperationalUpdate
  },
  props: {
    rent: Object,
    periodRentId: String,
  },
  data() {
    return {
      urlGetApi: "/api/v1/operational/items-rent/get-all",
      urlDelete: "/api/v1/operational/items-rent/delete",
      data: [],
      item: {},
      loading: false,
      content: {
        data: [],
        totalRecords: 0,
      },
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      selected: [],
      showSelectAll: false,
      dataDraggable: [],
      orderByDraggable: "",
      showTableDraggable: false,
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["groupBy", "showModal"]),
    ...mapState("user", ["userLogged"]),
    ...mapState("generic", ["event"]),
    grouped() {
      var list = this.groupBy({
        array: this.content.data,
        key: "groupRentName",
      });
      return list;
    },
    allowOutputGreater() {
      return this.userLogged.parameterStock.allowOutputGreater;
    },
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: { any: this.periodRentId },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    update(item) {
      this.modalOptions = {
        name: "itemRentUpdate",
        title: "Produto",
        width: 1000,
      };
      this.openModal("optionsItemsRent");
      this.addEvent({ name: "itemRentUpdate", data: item });
    },
    totalizationAndHistory(item) {
      this.modalOptions = {
        name: "totalizationAndHistory",
        title: item.productFullName,
        width: 830,
      };
      this.item = item;
      this.addEvent({ name: "totalizationAndHistory", data: item });
      this.openModal("optionsItemsRent");
    },
    confirmRemove() {
      this.removeLoading(["btnRemoveItem"]);
    },
    removeSelected() {
      let params = {
        url: this.urlDelete,
        selected: this.selected,
      };
      this.deleteAllApi(params).then((reponse) => {
        if (reponse.success) {
          this.reset({ showAll: false, period: { id: this.periodRentId } });
          this.hideModal();
        }
        this.addEvent({ name: "updateTotalizationRent" });
        this.addEvent({ name: "removedItemRent" });
        this.removeLoading(["confirm"]);
      });
    },
    reset() {
      this.selected = [];
      this.showSelectAll = false;
      this.dataDraggable = [];
      this.showTableDraggable = false;
      this.getAll();
    },
    endScrollBar() {
      if (this.content.data.length != this.content.totalRecords) {
        this.baseParams.currentPage++;
        this.getAll();
      }
    },
    select() {
      this.selected = [];
      if (this.showSelectAll) {
        for (let i in this.content.data) {
          this.selected.push(this.content.data[i].id);
        }
      }
    },
    changeItemToTableDraggable(data) {
      this.orderByDraggable = "item";
      this.dataDraggable = [];
      this.showTableDraggable = true;
      data.forEach((item) => {
        let obj = { id: item.id, name: item.productFullName };
        this.dataDraggable.push(obj);
      });
    },
    changeGroupToTableDraggable(data) {
      this.orderByDraggable = "group";
      this.dataDraggable = [];
      var group = Object.entries(data);
      group.forEach((item) => {
        let obj = { id: item[1][0].groupRentId, name: item[0] };
        this.dataDraggable.push(obj);
      });
      this.showTableDraggable = true;
    },
    isShowTableSub(itemId) {
      return this.itemsAccessory.ids.includes(itemId);
    },
    onHideModal() {
      this.getAll();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "closedFixedBar") {
          this.selected = [];
          this.showSelectAll = false;
        }

        if (event.name == "updateFixedBar") {
          this.selected = [];
          this.showSelectAll = false;
          this.getAll();
        }


        if (event.name == "removeItemFixedBar") {
          this.modalOptions = {
            name: "confirmRemove",
            title: "Tem certeza que deseja excluir?",
            width: 500,
          };
          this.openModal("optionsItemsRent");
          this.confirmRemove();
        }

        if (event.name == "timeLineAvailability") {
          this.modalOptions = {
            name: "timeLineAvailability",
            title: "Painel",
            width: 1000,
          };
          this.openModal("optionsItemsRent");
        }

        if (event.name == "addedProduct")
          this.reset(event.data);

        if (event.name == "cancelTableDraggable")
          this.showTableDraggable = false;

        if (event.name == "confirmedTableDraggable")
          this.reset({ showAll: false, period: { id: this.periodRentId } });

      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.div-accessory {
  margin-top: 8px;
}

.group {
  background-color: #fafafc;
  font-size: 14px;
  font-weight: 400;
}

.div-filter {
  margin-bottom: 30px;
  padding: 10px;
}

.with-only-stock {
  cursor: pointer;
  margin-left: 20px;
  color: #757d8c;
}

.icon-only-stock {
  color: #757d8c;
}

.title-group {
  font-size: 14px;
  margin-left: 10px;
}

.name-item {
  font-size: 16px;
  margin-bottom: 8px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-product {
  width: 800px;
  background-color: #f1f4f9;
  cursor: pointer;
}

.td-quantity {
  width: 150px;
}

.quantity {
  cursor: pointer;
}

.quantity:hover {
  text-decoration: underline;
}

.description {
  font-weight: 400;
  color: grey;
}

.without-group {
  font-size: 13px;
  font-style: italic;
  color: grey;
}

.icon-cursor {
  cursor: pointer;
}

.icon-order-group {
  color: #779cb3;
}

.text-order {
  font-size: 13px;
  margin-left: 10px;
  font-style: italic;
}

.td-checkbox {
  width: 30px;
}
</style>
