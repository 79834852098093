<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <AddressRentCreateUpdate :rent="rent" />
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import AddressRentCreateUpdate from "./AddressRentCreateUpdate.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations } from "vuex";

export default {
  name: "AddressRentList",
  components: {
    ViewTemplateConfiguration,
    Button,
    AddressRentCreateUpdate,
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/rental/address-rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/address-rent/delete",
        urlOrderAllApi: "/api/v1/rental/address-rent/order-all",
        dragAndDrop: true,
        headerTable: [
          {
            field: "description",
            title: "Descrição",
            type: "link",
            eventName: "addressRentUpdate",
          },
          {
            field: "address",
            title: "Endereço",
            type: "text",
          },
        ],
      },
      propsParam: {
        any: this.rent.id,
      },
    };
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    create() {
      this.addEvent({ name: "addressRentCreate" });
      this.removeLoading(["btnAddressRentCreate"]);
    },
  },
};
</script>
