<template>
  <div>
    <b-row>
      <b-col xs="12" sm="3" md="12" lg="12" xl="3">
        <InputText title="Descrição" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="addressRent.description" :markFormDirty="false" />
      </b-col>
      <b-col xs="12" sm="7" md="12" lg="12" xl="7">
        <InputAddressGoogle v-if="isGoogleMaps" title="Endereço" field="address" :formName="formName" :required="true"
          :maxLength="200" v-model="addressRent.address" :markFormDirty="false" />
        <InputText v-else title="Endereço" field="address" :formName="formName" :required="true" :maxLength="200"
          v-model="addressRent.address" :markFormDirty="false" />
        <RadioGroup :formName="formName" field="group" :options="[
          { text: 'Google Maps', value: true },
          { text: 'Livre', value: false },
        ]" :markFormDirty="false" v-model="isGoogleMaps" />
      </b-col>
      <b-col xs="12" sm="7" md="12" lg="2" xl="2">
        <div class="div-button">
          <Button _key="btnAddAddressRent" :type="addressRent.id ? 'success' : 'primary'"
            :disabled="!isFormValid(formName)" :classIcon="addressRent.id ? 'fa-solid fa-check' : 'fa-regular fa-plus-large'
          " size="small" :clicked="saveAddressRent" />
          <Button _key="btnImportAddress" v-if="!addressRent.id" :disabled="!rent.customer.id"
            :classIcon="'fa-solid fa-arrow-right-from-arc'" type="info" size="small" :clicked="importAddress" />
          <Button _key="btnCancelAddress" v-if="addressRent.id" type="danger" classIcon="fa-solid fa-xmark" size="small"
            :clicked="cancel" />
        </div>
      </b-col>
    </b-row>
    <Modal title="Importar" :width="700" :height="750" v-show="showModal('importAddress')">
      <ImportAddress :rent="rent" />
    </Modal>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputAddressGoogle from "@nixweb/nixloc-ui/src/component/forms/InputAddressGoogle";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";

import AddressRent from "@/components/modules/rental/address-rent/AddressRent.js";
import ImportAddress from "./ImportAddress.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "AddressRentCreateUpdate",
  components: {
    InputText,
    InputAddressGoogle,
    Button,
    Modal,
    ImportAddress,
    RadioGroup,
  },
  props: {
    rent: Object,
    module: {
      type: String,
      default: "Rental",
    },
  },
  data() {
    return {
      formName: "addressRentCreateUpdate",
      addressRent: new AddressRent(),
      urlCreate: "/api/v1/rental/address-rent/create",
      urlUpdate: "/api/v1/rental/address-rent/update",
      isGoogleMaps: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapMutations("pdv", ["addAddress"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveAddressRent() {
      if (this.module == "Rental") {
        this.addressRent.rentId = this.rent.id;
        if (this.addressRent.id) {
          let params = { url: this.urlUpdate, obj: this.addressRent };
          this.putApi(params).then((response) => {
            if (response.success) this.cancel();
            this.removeLoading(["btnAddAddressRent"]);
          });
        } else {
          let params = { url: this.urlCreate, obj: this.addressRent };
          this.postApi(params).then((response) => {
            if (response.success) {
              this.hideModal();
              this.cancel();
            }
            this.removeLoading(["btnAddAddressRent", "btnImportAddressRent"]);
          });
        }
      }
      if (this.module == "PDV") {
        this.addAddress(this.addressRent);
        this.hideModal();
        this.cancel();
        this.removeLoading(["btnAddAddressRent", "btnImportAddressRent"]);
      }
    },
    update(data) {
      this.addressRent = new AddressRent();
      this.addressRent.update(data);
      this.removeLoading([data.id]);
    },
    cancel() {
      this.addressRent = new AddressRent();
      this.resetValidation(this.formName);
      this.removeLoading(["btnCancelAddress"]);
    },
    importAddress() {
      this.openModal("importAddress");
      this.removeLoading(["btnImportAddress"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "addressRentCreate") {
          this.resetValidation(this.formName);
          this.addressRent = new AddressRent();
        }
        if (event.name == "addressRentUpdate") this.update(event.data);

        if (event.name == "importAddress") {
          this.addressRent.description = event.data.description;
          this.addressRent.address = event.data.formattedAddress;
          this.saveAddressRent();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-button {
  margin-top: 38px;
}
</style>