<template>
  <div>
    <Select title="Cliente (Nome ou CNPJ/CPF)" field="customer" :formName="formName" :required="required"
      :disabled="disabled" url="/api/v1/crm/customer/select-all" v-model="customer"
      nameNewRegister="customerSupplierCreateUpdate" :clickedNewRegister="open" :showNewRegister="hasRule('CRM')"
      titleNewRegister="Cliente" :widthNewRegister="1200" :heightNewRegister="250">
      <br />
    </Select>
    <SideBar title="Cliente">
      <CustomerSupplierCreate formName="customerSupplierCreateUpdate" :registerInSelect="true" v-model="customer" />
    </SideBar>
  </div>
</template>
<script>
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SideBar from "@nixweb/nixloc-ui/src/component/forms/SideBar";

import CustomerSupplierCreate from "../../shared/customer-supplier/CustomerSupplierCreate.vue";
import ContactCreateUpdate from "../../shared/contact/ContactCreateUpdate.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CustomerWithCreate",
  props: ["formName", "disabled", "value"],
  props: {
    formName: String,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: true,
    },
    value: Object
  },
  components: {
    Select,
    ScrollBar,
    Button,
    SideBar,
    CustomerSupplierCreate,
    ContactCreateUpdate,
  },
  data() {
    return {
      customer: { id: "", content: "" },
    };
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("validation", ["isFormValid"]),
  },
  methods: {
    ...mapMutations("generic", ["openSideBar", "hideSideBar", "removeLoading"]),
    open() {
      this.openSideBar();
    },
    cancel() {
      this.removeLoading(["cancelSaveCancel"]);
      this.hideSideBar();
    },
  },
  watch: {
    customer: {
      handler(customer) {
        this.$emit("input", customer);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.customer = value;
      },
      deep: true,
    },
  },
};
</script>
