<template>
  <div>
    <div>
      <Loading type="line" :center="false" v-show="loading" />
      <b-row>
        <b-col sm="12">
          <TableTotalRecords :totalRecords="content.totalRecords" />
        </b-col>
      </b-row>
    </div>
    <ScrollBar id="items-rent" :minHeight="400" :maxHeight="800">
      <table class="table table-responsive-xs">
        <thead>
          <tr>
            <th>
              <span class="title-header">Gerado</span>
            </th>
            <th class="text-center">
              <span class="title-header">Tipo</span>
            </th>
            <th class="text-center">
              <span class="title-header">Usuário</span>
            </th>
            <th class="text-center">
              <span class="title-header">Origem</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="item in content.data">
          <tr>
            <td>{{ item.generationDate }}</td>
            <td class="text-center">
              <div class="badge badge-rent td-margin" v-if="item.statusMovimentName == 'Saída'">
                Saída
              </div>
              <div class="badge badge-returned td-margin" v-if="item.statusMovimentName == 'Retorno'">
                Retorno
              </div>
            </td>
            <td class="text-center td-margin">{{ item.generationUser }}</td>
            <td class="text-center td-margin">
              <span v-if="item.description">
                {{ item.description }}
              </span>
              <span v-else>N/I</span>
            </td>
            <td class="text-right">
              <div class="side-by-side td-dropdown">
                <Dropdown title="Mais..." type="info" size="small" position="right" classIcon="fa-solid fa-chevron-down"
                  :param="item" :items="options" />
                <br />
                <div></div>
              </div>
              <div class="side-by-side">
                <ButtonSub :itemId="item.id" urlGet="/api/v1/stock/items-moviment/get-all"
                  eventNameShow="showItemsMoviment" eventNameHide="hideItemsMoviment"
                  :propsParam="{ any: item.id, noPaginated: true }" v-model="buttonSub" />
              </div>
            </td>
          </tr>
          <td colspan="8" v-if="buttonSub.itemId == item.id">
            <Loading type="line" :center="false" />
          </td>
          <td colspan="8" v-if="isShowTableSub(item.id)">
            <table class="table-sub table table-responsive-xs">
              <thead>
                <tr>
                  <th>
                    <span class="title-header">Produto</span>
                  </th>
                  <th class="text-center">
                    <span class="title-header">Quantidade</span>
                  </th>
                  <th class="text-center" v-if="manageByPatrimony">
                    <span class="title-header">Patrimônio(s)</span>
                  </th>
                  <th class="text-center">
                    <span class="title-header">Período</span>
                  </th>
                </tr>
              </thead>
              <tbody v-for="itemMoviment in itensMovimentByMovimentId(item.id)">
                <tr>
                  <td>{{ itemMoviment.productFullName }}</td>
                  <td class="text-center">{{ itemMoviment.quantity }}</td>
                  <td class="text-center" v-if="manageByPatrimony">
                    {{ itemMoviment.patrimony }}
                  </td>
                  <td class="text-center td-description">
                    {{ itemMoviment.periodRentName }}
                  </td>
                </tr>
              </tbody>
              <tbody v-show="itensMovimentByMovimentId(item.id).length == 0">
                <tr>
                  <td colspan="8">
                    <span class="title">Nenhum registro encontrado!</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="8">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollBar>
    <PrintPreview v-if="print" module="MovementStock" :rentId="rent.id" :movimentId="movimentId"
      :onHideModal="onHideModal" />
  </div>
</template>
<script>

import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import ButtonSub from "@nixweb/nixloc-ui/src/component/forms/ButtonSub.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";

import PrintPreview from '../../adm/document/PrintPreview.vue'

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsLista",
  components: {
    Loading,
    ScrollBar,
    Dropdown,
    TableTotalRecords,
    ButtonSub,
    PrintPreview
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      urlGetApi: "/api/v1/stock/moviment/get-all",
      data: [],
      loading: true,
      movimentId: "",
      print: false,
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
      options: [
        {
          title: "Imprimir",
          classIcon: "fa-solid fa-print-magnifying-glass",
          eventName: "printMoviment",
          hr: true,
        },
      ],
      itensMoviment: {
        ids: [],
        data: [],
      },
      buttonSub: {},
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapState("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          rentId: this.rent.id,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    isShowTableSub(itemId) {
      return this.itensMoviment.ids.includes(itemId);
    },
    itensMovimentByMovimentId(movimentId) {
      let filter = this.itensMoviment.data.filter(
        (x) => x.movimentId == movimentId
      );
      return filter;
    },
    onHideModal() {
      this.print = false;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "showItemsMoviment") {
          let self = this;

          setTimeout(function () {
            self.itensMoviment.ids.push(event.data.itemId);
          }, 400);

          event.data.data.forEach((item) => {
            self.itensMoviment.data.push(item);
          });
        }

        if (event.name == "hideItemsMoviment") {
          let filterData = this.itensMoviment.data.filter(
            (x) => x.movimentId != event.data.itemId
          );

          this.itensMoviment.data = filterData;

          let filter = this.itensMoviment.ids.filter(
            (x) => x != event.data.itemId
          );
          this.itensMoviment.ids = filter;
        }

        if (event.name == "printMoviment") {
          this.print = true;
          this.movimentId = event.data.id;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.table-sub {
  background-color: #f3f3f3;
}

.td-description {
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-size: 14px;
  color: #757d8c;
  font-style: italic;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-dropdown {
  overflow: visible;
}

.group {
  background-color: #fafafc;
  font-size: 14px;
  font-weight: 400;
}

.title-group {
  font-size: 14px;
  margin-left: 10px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-product {
  width: 500px;
  background-color: #f1f4f9;
  cursor: pointer;
}

.td-quantity {
  width: 150px;
}

.description {
  font-weight: 400;
  color: grey;
}

.without-group {
  font-size: 13px;
  font-style: italic;
  color: grey;
}

.icon-cursor {
  cursor: pointer;
}

.icon-order-group {
  color: #779cb3;
}

.text-order {
  font-size: 13px;
  margin-left: 10px;
  font-style: italic;
}

.td-checkbox {
  width: 30px;
}

.td-margin {
  margin-top: 10px;
}

.badge {
  font-size: 13px !important;
  margin-left: 5px;
  border-radius: 10px !important;
  padding: 8px;
  color: white;
}

.badge-rent {
  background-color: #009183;
}

.badge-returned {
  background-color: #b8860b;
}

.badge-renewed {
  background-color: #577696;
}
</style>
