export default class ItemsRent {
    constructor() {
        this.id = "";
        this.groupRent = { id: "", content: "" };
        this.typeProduct = { id: "", content: "" };
        this.product = { id: "", content: "" };
        this.productId = "";
        this.periodRent = { id: "", content: "" };
        this.periodRentId = "";
        this.description = "";
        this.quantity = 1;
        this.statusStockItemsRent = 0;
        this.rentId = "";
    }
    update(data) {
        this.id = data.id;
        this.groupRent = { id: data.groupRentId, content: data.groupRentName };
        this.typeProduct = { id: data.typeProductId, content: data.typeProductName };
        this.product = { id: data.productId, content: data.productName };
        this.productId = data.productId;
        this.periodRent = { id: data.periodRentId, content: data.periodRentName };
        this.periodRentId = data.periodRentId;
        this.description = data.description;
        this.quantity = data.quantity;
        this.statusStockItemsRent = data.statusStockItemsRent;
        this.rentId = data.rentId;
    }
}