<template>
    <div class="div-discount">
        <InputDecimalDiscount title="Desconto Unitário" :markFormDirty="false" field="unitDiscount" :required="false"
            _style="color:red;" :maxLength="5" type="float" v-model="discount" />
        <div class="div-apply text-center">
            <Button _key="btnApplyDiscount" title="Aplicar" type="danger" size="medium" :clicked="update" />
        </div>
    </div>
</template>
<script>

import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "ApplyDiscount",
    props: ["rentId", "selected"],
    components: { InputDecimalDiscount, Button },
    data() {
        return {
            urlUpdate: "/api/v1/rental/items-rent/apply-discount",
            discount: { discountByPercentage: false, discount: 0 },
        }
    },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        update() {
            let params = {
                url: this.urlUpdate,
                obj: {
                    listIds: this.selected,
                    rentId: this.rentId,
                    discountByPercentage: this.discount.discountByPercentage,
                    unitDiscount: this.discount.discount
                }
            };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "updateFixedBar" });
                    this.hideModal();
                }
                this.removeLoading(["btnApplyDiscount"]);
            });
        }
    }

}

</script>