export default class AddressRent {
    constructor() {
        this.id = "";
        this.description = "";
        this.address = "";
        this.rentId = "";
    }
    update(data) {
        this.id = data.id;
        this.description = data.description;
        this.address = data.address;
    }
}